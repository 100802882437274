<template>
  <el-row justify="center">
    <el-col :lg="7" :xl="5" :md="10" :sm="12" :xs="20">
      <el-card style="min-width: 300px; max-width:400px">
        <h3 class="uppercase align-center no-margin-top">Регистрация</h3>
        <el-form ref="form" :model="form" :rules="formRules" label-position="top" class="align-center"
                 data-test="regForm">
          <el-form-item label="Номер телефона" prop="phone">
            <phone-input v-model="form.phone"/>
            <!--          <el-input autocomplete="tel" v-model="form.phone" tel-country-code="7"></el-input>-->
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="Имя" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Код привлекателя" prop="agentCode" v-if="form.agentCode">
            <el-input v-model="form.agentCode" disabled></el-input>
          </el-form-item>
          <CaptchaInput v-model="form.captcha" ctype="reg_code" ref="regCaptcha"/>

          <el-form-item>
            <el-button type="success" @click="getCode" class="uppercase full-width" size="medium" :loading="loading"
                       :disabled="!formComplete">Получить доступ
            </el-button>
          </el-form-item>

          <el-button text @click="$router.push({path : '/login'})" data-test="passResetButton"  style="margin-top: 20px">Назад к логину
          </el-button>
        </el-form>
      </el-card>
    </el-col>
  </el-row>

</template>

<script>


import CaptchaInput from "@shared/components/captcha-input.component.vue";
import PhoneInput from "@shared/components/phone-input.component.vue";

export default {
  name: "Registration",
  components: {PhoneInput, CaptchaInput},
  title: "Регистрация",
  mounted() {
    // this.getNewCaptcha()
  },
  computed: {
    formComplete() {
      return this.form.phone && this.form.email && this.form.name && this.form.captcha
    }
  },
  beforeMount() {
    if (this.$route.params && this.$route.params.agent_code) {
      this.form.agentCode = this.$route.params.agent_code
    }
  },
  methods: {

    getCode: function () {
      this.$refs.form.validate(isValid => {
        if (!isValid) return

        this.loading = true
        this.axios.post("/auth/initializeRegistration", this.form, {withCredentials: true})
            .then(resp => {
              this.loading = false
              if (resp.data.error || !resp.data.regId) {
                this.$refs.regCaptcha.getNewCaptcha()
                if (resp.data.error === "wrongCaptcha")
                  return this.$gNotify("Неправильный код с картинки", "error")
                if (resp.data.error === "duplicateEmail")
                  return this.$gNotify("Такой email уже используется", "error")
                if (resp.data.error === "duplicatePhone")
                  return this.$gNotify("Такой  номер телефона уже  используется", "error")

              } else {
                this.$router.push({"path": `/confirmCode`, query: {regId: resp.data.regId}})
              }
            })
      })
    }
  },
  data() {
    return {
      form: {
        phone: "",
        email: "",
        name: "",
        agentCode: "",
        captcha: ""
      },
      formRules: {
        phone: [{required: true, trigger: "blur", message: "Укажите корректный номер телефона", len: 11}],
        email: [{required: true, trigger: "blur", type: "email", message: "Укажите корректный e-mail адрес"}],
        name: [{required: true, trigger: "blur", message: "Заполните ваше имя"}],
        agentCode: [{required: false}],
      },
      loading: false
    }
  },
}

</script>